@import '../../../scss/theme-bootstrap';
@import '../../../scss/components/_mpp_filters';

.elc-mobile-filters-modal {
  height: auto;
  &-button {
    display: flex;
    justify-content: center;
    .elc-filter-icon {
      @include swap_direction(margin, -3px 0 0 5px);
    }
  }
  .elc-checkbox-label {
    @include text-body-text;
    color: $cr19-text;
    margin-#{$ldirection}: 15px;
  }
  .elc-mobile-apply-filter-label {
    @include text-title--small;
    color: $cr19-text-white;
    text-transform: capitalize;
  }
  .elc-mobile-filter-type-label,
  .elc-header-label {
    @include text-title--small;
    color: $cr19-text;
  }
  .elc-mobile-tag-filters-title,
  .elc-header-label {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .elc-mobile-category-filters-title {
    @include text-body-text--large;
  }
  .elc-mobile-tag-filters-checkbox-wrapper {
    .elc-checkbox {
      &:focus {
        outline: none;
      }
    }
  }
}

.breadcrumbs {
  @include container-padding-rules;
  @include container-max-width-x-large;
  .breadcrumbs__level,
  .breadcrumbs__level a {
    color: $cr19-text-grey;
    font-size: 15px;
    letter-spacing: 0.02em;
    line-height: get-line-height(15px, 24px);
  }
  .breadcrumbs__level {
    display: none;
    @media #{$cr19-medium-up} {
      display: inline;
    }
    &::before {
      content: ' <';
      @media #{$cr19-medium-up} {
        content: ' /';
      }
    }
    &:first-child,
    &:last-child {
      &::before {
        @media #{$cr19-medium-up} {
          content: '';
        }
      }
    }
    &:last-child {
      &::before {
        content: '';
      }
    }
    &:nth-last-child(3) {
      display: inline;
    }
  }
}

.sd-product-grid {
  @include container-padding-rules;
  @include container-max-width-x-large;
  min-height: 500px;
  @media #{$cr19-large-up} {
    margin: auto;
  }
  [data-component='product-grid'] {
    &.sd-mounted {
      @include fade-in(0.25s, ease-in-out);
      height: auto;
      overflow-y: visible;
      pointer-events: all;
      position: relative;
      visibility: visible;
    }
  }
  .slick-list {
    .slick-slide {
      margin: 0;
      padding: 0 4px;
    }
  }
  .elc-styled-filters {
    display: flex;
    margin-top: 16px;
    box-shadow: none;
    .elc-sort-wrapper {
      order: 2;
      margin-#{$ldirection}: auto;
    }
    &-category-row {
      padding: 0;
      order: 1;
      width: auto;
      .elc-grid-column {
        display: flex;
        flex: 1;
      }
    }
    &-button-row {
      order: 3;
      padding-top: 16px;
      width: 100%;
    }
    &-clear-all-button {
      margin: 0 0 18px;
      order: 4;
    }
    &-selected-category-row {
      flex: 1;
      order: 5;
      padding: 0 0 12px;
    }
    &-result-row {
      @include text-body-text--no-xl;
      order: 6;
      padding-top: 0;
      width: 100%;
    }
    &-clear-all-button,
    &-applied-filter {
      background-color: transparent;
      border: 0;
      color: $cr19-text;
      font-weight: normal;
      height: auto;
      line-height: 1;
      margin-#{$rdirection}: 28px;
      min-width: auto;
      padding: 0;
      text-transform: capitalize;
      width: auto;
      .elc-remove-icon {
        display: inline-block;
        height: 10px;
        margin-#{$ldirection}: 3px;
      }
      &:hover,
      &:active {
        background-color: transparent;
      }
    }
    &-category-tab {
      @include text-title--small;
      background-color: transparent;
      border: 0;
      font-weight: normal;
      height: auto;
      line-height: 1;
      min-width: auto;
      padding-#{$rdirection}: 60px;
      width: auto;
      @media #{$cr19-xlarge-up} {
        font-size: 17px;
        line-height: get-line-height(17px, 24px);
      }
      &.active {
        color: $cr19-text-dark;
      }
      &:hover,
      &:active {
        background-color: transparent;
        color: $cr19-text-dark;
      }
    }
    &-category-button.button--light,
    &-category-button.button--dark {
      @include swap_direction(margin, 0 24px 16px 0);
      border: 1.5px solid $cr19-border-light;
      height: 64px;
      min-width: auto;
      padding: 0 20px;
      text-transform: capitalize;
      width: auto;
    }
  }
  .elc-dropdown-option {
    @include text-link;
    @include text-link--style-2;
    flex-direction: row;
    &:first-child {
      border-bottom: 0.01em solid $cr19-border-light;
    }
  }
  .elc-dropdown {
    width: 300px;
    background: transparent;
    @media #{$cr19-medium-up} {
      position: relative;
      #{$rdirection}: 0;
    }
    &-wrapper {
      border: none;
    }
    &-label {
      color: $cr19-text;
      letter-spacing: -0.01em;
      line-height: get-line-height(15px, 24px);
    }
    &-arrow {
      border: none;
      color: $cr19-text;
      padding: 0;
    }
    &-arrow-icon {
      fill: $cr19-text;
    }
    &-readonly-input {
      @include text-body-text--no-xl;
      color: $color-cl-light-black;
      justify-content: flex-end;
      @media #{$cr19-xlarge-up} {
        font-size: 17px;
        line-height: get-line-height(17px, 24px);
      }
      input {
        @include text-title--small;
      }
    }
  }
  .elc-mobile-filters {
    justify-content: space-between;
    .elc-mobile-attribute-filters-results {
      @include text-body-text;
      order: 3;
      margin: 12px 0;
    }
    .elc-sort-wrapper {
      flex: 1;
      order: 2;
      padding: 0;
      select {
        @include text-title--small;
        background-image: linear-gradient(45deg, transparent 50%, $cr19-text 50%),
          linear-gradient(135deg, $cr19-text 50%, transparent 50%);
        background-position: #{$rdirection} 20px center, #{$rdirection} 15px center;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        border: 1.5px solid $cr19-border-light;
        color: $cr19-text;
        height: 56px;
        padding-#{$rdirection}: 20px;
        text-align-last: center;
        width: 100%;
      }
    }
  }
  .elc-product-grid-top-border {
    margin: 0 auto;
    box-shadow: none;
    justify-content: space-evenly;
    @media #{$cr19-medium-up} {
      justify-content: unset;
    }
    .elc-styled-gifts-filter-title-row {
      @include text-title--med--no-xl;
      display: block;
      box-shadow: $color-black-12opacity 0 -1px 0 0;
      padding-bottom: 25px;
      padding-top: 25px;
      padding-#{$ldirection}: 0;
      width: 100%;
      @media #{$cr19-medium-up} {
        box-shadow: $color-black-12opacity 0 1px 0 0;
      }
      .elc-styled-gifts-filter-label {
        @include text-title--med--no-xl;
      }
    }
    .elc-styled-tag-filters-column,
    .elc-styled-filters-column {
      padding: 10px 0;
      .elc-mobile-filters-modal-button {
        @include text-title--small;
        border: 1.5px solid $cr19-border-light;
        border-radius: unset;
        color: $cr19-text;
        height: 56px;
        text-transform: capitalize;
        width: 140px;
      }
    }
    .elc-sort-wrapper {
      padding: 10px 0;
      margin-#{$ldirection}: 0;
      width: auto;
      @media #{$cr19-medium-up} {
        margin-#{$ldirection}: auto;
        position: relative;
        width: 0;
      }
      select {
        @include text-title--small;
        border: 1.5px solid $cr19-border-light;
        color: $cr19-text;
        height: 56px;
        padding-#{$rdirection}: 20px;
        background-image: linear-gradient(45deg, transparent 50%, $cr19-text 50%),
          linear-gradient(135deg, $cr19-text 50%, transparent 50%);
        background-position: #{$rdirection} 20px center, #{$rdirection} 15px center;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        text-align-last: center;
        width: 140px;
        @media #{$cr19-medium-up} {
          background-image: none;
          border: none;
        }
      }
      .elc-product-sorting-dropdown {
        color: $cr19-text;
        margin-top: 10px;
      }
      .elc-dropdown-option {
        @include text-link;
        @include text-link--style-2;
        flex-direction: row;
        &:first-child {
          border-bottom: 0.01em solid $cr19-border-light;
        }
      }
      .elc-dropdown {
        width: 240px;
        @media #{$cr19-medium-up} {
          position: absolute;
          #{$rdirection}: 0;
        }
        &-wrapper {
          border: none;
        }
        &-label {
          color: $cr19-text;
          letter-spacing: -0.01em;
          line-height: get-line-height(15px, 24px);
        }
        &-arrow {
          border: none;
          color: $cr19-text;
          padding: 0;
        }
        &-arrow-icon {
          fill: $cr19-text;
        }
        &-readonly-input {
          @include text-title--small;
          justify-content: flex-end;
          @media #{$cr19-xlarge-up} {
            font-size: 17px;
            line-height: get-line-height(17px, 24px);
          }
        }
      }
    }
    .elc-styled-tag {
      &-filters {
        &-category-row,
        &-button-row {
          .elc-styled-tag-filters {
            &-category-tab,
            &-price-tab,
            &-button {
              @include text-title--small;
              font-weight: normal;
              @media #{$cr19-xlarge-up} {
                font-size: 17px;
                line-height: get-line-height(17px, 24px);
              }
            }
            &-button {
              @include swap_direction(margin, 0 18px 12px 0);
              border: 1.5px solid $cr19-border-light;
              height: 70px;
              min-width: auto;
              padding: 23px;
              text-transform: capitalize;
              width: auto;
            }
          }
        }
        &-category-row {
          &-category-tab,
          &-price-tab {
            color: $cr19-text-dark;
            &.active {
              color: $cr19-text;
            }
          }
        }
        &-results-label {
          @include text-title--med;
          @media #{$cr19-xlarge-up} {
            font-size: 28px;
            line-height: get-line-height(28px, 32px);
          }
        }
      }
      &-applied-filters {
        @include text-body-text--no-xl;
        @include swap_direction(margin, 0 28px 0 0);
        padding: 3px 0 40px;
      }
    }
    .elc-styled-filters-category-row {
      @media #{$cr19-medium-up} {
        padding-#{$rdirection}: 250px;
      }
    }
    .elc-styled-selected-tags-row {
      padding: 0;
    }
    &.elc-product-grid-with-sort-only {
      .elc-sort-wrapper,
      select {
        width: 100%;
      }
      .elc-dropdown {
        margin-#{$ldirection}: auto;
      }
    }
  }
  .elc-grid-container {
    @include quickshop;
    @include shade-picker-style;
    margin: 12px 0;
    @media #{$cr19-medium-up} {
      margin: 0;
    }
    .elc-styled {
      &-filters-column {
        @media #{$cr19-medium-up} {
          width: 100%;
        }
      }
      &-category-filters-button {
        min-width: 0;
        width: auto;
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &-category-filters-clear-all-button,
      &-category-applied-filters {
        .elc-remove-icon {
          height: 10px;
          display: inline-block;
          margin-#{$ldirection}: 3px;
        }
      }
    }
    .slick-slider {
      margin-bottom: 0;
    }
    .elc-product-brief-view {
      margin: 0 -8px;
      @media #{$cr19-medium-up} {
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        padding: 0 -12px;
      }
      @media #{$cr19-large-up} {
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      }
      .elc-grid-item-product,
      .elc-grid-item-featured-product,
      .elc-grid-item-tout {
        @media #{$cr19-large-up} {
          padding: 12px;
        }
      }
      .elc-grid-item-featured-product {
        .elc-product-image-section {
          position: relative;
          .elc-product-quick-view-button-wrapper {
            position: unset;
          }
        }
      }
    }
    .elc-product-brief-wrapper {
      background: none transparent;
    }
    .elc-slider-view-wrapper {
      padding: 0;
    }
    .elc-product-carousel-slider-view {
      @media #{$cr19-medium-up} {
        margin: 0 -12px;
      }
    }
    .slick-slide {
      margin: 0;
      @media #{$cr19-medium-up} {
        padding: 0 12px;
      }
    }
    // ELC basic tout
    .content-block {
      &.basic-tout {
        overflow: unset;
        @media #{$cr19-large-up} {
          overflow: hidden;
        }
        .content-block {
          height: 100%;
          overflow: unset;
          &.align-left {
            align-items: inherit;
          }
        }
        h4 {
          font-size: 34px;
          line-height: 0.9;
          padding-bottom: 10px;
          @media #{$cr19-large-up} {
            font-size: 30px;
            line-height: initial;
            padding-bottom: initial;
          }
        }
        &__content {
          height: auto;
          .mantle-custom-text {
            p {
              font-size: inherit;
              line-height: inherit;
            }
          }
        }
        &__content-wrapper {
          position: relative;
        }
        &__media-wrapper {
          height: auto;
        }
        .content-block__links {
          a {
            text-decoration: none;
          }
        }
        .mobile-flex-hidden {
          display: none;
          @media #{$cr19-large-up} {
            display: flex;
          }
        }
        .pc-hidden {
          @media #{$cr19-large-up} {
            display: none;
          }
        }
        .mantle-media-asset img,
        .mantle-media-asset picture,
        .mantle-media-asset video {
          max-width: 100%;
          position: unset;
          #{$ldirection}: unset;
          #{$rdirection}: unset;
          margin-#{$ldirection}: unset;
          margin-#{$rdirection}: unset;
          width: 100%;
        }
        .mantle-media-asset video {
          height: 100%;
          object-fit: cover;
        }
      }
      .basic-tout {
        &__wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
        }
        &__media-wrapper {
          .elc-grid-container & {
            height: 100%;
          }
        }
        &__media {
          height: 100%;
          .elc-img {
            height: auto;
          }
        }
        &__content-above-block,
        &__content-below-block,
        &__content-over-media {
          .content-block__links {
            margin-top: 25px;
          }
        }
        &__content-above-block,
        &__content-below-block {
          .content-block__line--header {
            padding-bottom: 10px;
          }
        }
        &__content-above-block {
          height: auto;
          padding-bottom: 32px;
          padding-top: 15px;
        }
        &__content-below-block {
          display: block;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        &__content-wrapper {
          position: relative;
        }
        &__content-over-media {
          position: absolute;
          top: 0;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          .content-block__line {
            padding-bottom: 0;
            .special-text--body {
              padding-bottom: 10px;
              text-transform: uppercase;
            }
            .text-link--style-1 {
              text-decoration: underline;
              text-transform: uppercase;
              text-underline-offset: 5px;
            }
            &--text-row-1 {
              bottom: 2%;
              position: absolute;
              text-align: $ldirection;
            }
          }
          .content-block__line--content {
            margin-top: 16px;
            padding-bottom: 0;
          }
        }
      }
    }
    &.has-filters {
      .elc-product-brief-view {
        @media #{$cr19-medium-up} {
          margin-top: 44px;
          width: 75%;
          top: auto;
          float: $rdirection;
        }
        @media #{$cr19-large-up} {
          grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));
        }
        @media #{$cr19-medium-portrait-only} {
          width: 70%;
          margin-top: 48px;
        }
      }
      &.filters-applied {
        .elc-product-brief-view {
          @media #{$cr19-large-up} {
            margin-top: 73px;
          }
        }
      }
    }
  }
  .elc-grid-item-featured-product {
    .elc-product-images-wrapper,
    .elc-styled-product-image-container {
      width: calc(100% + 20px + 20px + 16px);
      margin-#{$ldirection}: -28px;
      @media #{$cr19-medium-up} {
        width: auto;
        margin-#{$ldirection}: 0;
      }
      .elc-img {
        width: calc(100% - 20px - 20px - 16px);
        margin: 0 auto;
      }
    }
  }
  + .text-tout-full-width {
    .content-block__links {
      padding: 0;
    }
  }
  @if $hide-mpp-ppu {
    @include hide-mpp-ppu;
  }
}

.field-elc-mpp-content {
  .sd-product-grid {
    [data-component='product-grid']:not(.sd-mounted) {
      .elc-product-images-wrapper:not(.lazyloaded) {
        @include shimmer(1.5s, ease-in-out);
      }
    }
    .elc-check-visibility {
      .elc-product-images-wrapper {
        animation: none;
      }
    }
  }
}

.elc-responsive-modal-mask .elc-mobile-filters-modal {
  #{$rdirection}: 0;
  max-width: 100%;
  min-height: 100%;
  min-width: 310px;
  padding-bottom: 90px;
  top: 0;
  transform: none;
  @media #{$cr19-large-up} {
    width: 440px;
  }
  .elc-mobile-category-filters-header-wrapper {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    .elc-mobile-category-filters-title {
      @include text-title--small;
      color: $cr19-text-grey;
      font-size: 17px;
    }
    .elc-mobile-category-filters-remove-icon-wrapper {
      background: none;
      border: none;
      height: auto;
      outline: none;
      padding: 0;
      width: auto;
      .elc-remove-icon {
        height: 15px;
        width: 15px;
      }
    }
  }
  .elc-mobile-apply-filter-button,
  .elc-styled-filters-clear-all-button {
    @include text-body-text;
    border-radius: 0;
    font-weight: normal;
    padding: 15px;
    text-transform: capitalize;
    .elc-remove-icon {
      display: none;
    }
  }
  .elc-styled-filters-clear-all-button {
    margin-#{$rdirection}: 6px;
  }
  .elc-mobile-filters-cta {
    #{$ldirection}: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    position: fixed;
    width: 100%;
  }
  .elc-styled-filters {
    &-category-tab-wrapper {
      border-bottom: 1px solid $cr19-border-light;
      &:last-of-type {
        border-bottom: 0 none;
      }
    }
    &-category-row {
      margin-bottom: 40px;
    }
    &-category-tab {
      @include text-title--small;
      background: none;
      border: none;
      color: $cr19-text;
      font-weight: normal;
      height: auto;
      outline: none;
      padding: 20px 0;
      position: relative;
      text-align: #{$ldirection};
      text-transform: capitalize;
      @media #{$cr19-xlarge-up} {
        font-size: 17px;
      }
      &:hover {
        background: none;
      }
      &::after {
        background: url('#{$base-theme-path}img/icons/src/plus-16.svg') no-repeat center;
        #{$rdirection}: 0;
        content: '';
        display: inline-block;
        height: 16px;
        position: absolute;
        width: 16px;
      }
      &.active {
        &::after {
          background: url('#{$base-theme-path}img/icons/src/minus-16.svg') no-repeat center;
        }
      }
    }
    &-button-row {
      margin-bottom: 48px;
      padding-top: 0;
      width: 100%;
    }
    &-category-filters-button {
      @include text-body-text;
      background: none;
      border: none;
      color: $cr19-text;
      font-weight: 500;
      height: auto;
      outline: none;
      text-align: #{$ldirection};
      text-transform: capitalize;
    }
    &-checkbox-label {
      width: 100%;
    }
  }
}

// QS ORDERABLE STYLES
.elc-product-quick-view-wrapper {
  @if $best-shade-message {
    @include best-shade-message(quickview);
  }
  .elc-right-column-top-section {
    @if $subline_as_product {
      @include subline_as_product;
    }
  }
}

.elc-mobile-filters-modal-wrapper {
  flex: 1;
  margin-#{$rdirection}: 20px;
  order: 1;
  width: auto;
  .elc-mobile-filters-modal-button {
    @include text-title--small;
    align-items: center;
    border-radius: unset;
    border: 1.5px solid $cr19-border-light;
    color: $cr19-text;
    display: flex;
    font-weight: normal;
    height: 56px;
    text-transform: capitalize;
    width: 100%;
  }
}
